import type { Category } from '@prezly/sdk';
import { translations } from '@prezly/theme-kit-intl';
import { type PaginationProps, useInfiniteStoriesLoading } from '@prezly/theme-kit-nextjs';
import { useIntl } from 'react-intl';

import { Button } from '@/components';
import type { StoryWithImage } from 'types';

import StoriesList from './StoriesList';

import styles from './InfiniteStories.module.scss';

type Props = {
    initialStories: StoryWithImage[];
    featuredStories?: StoryWithImage[];
    pagination: PaginationProps;
    category?: Category;
};

function InfiniteStories({ initialStories, featuredStories = [], pagination, category }: Props) {
    const { formatMessage } = useIntl();
    const filterQuery =
        featuredStories.length > 0 ? { id: { $nin: featuredStories.map((s) => s.id) } } : undefined;

    const { canLoadMore, isLoading, loadMoreStories, stories } = useInfiniteStoriesLoading(
        [...featuredStories, ...initialStories],
        pagination,
        category,
        ['thumbnail_image'],
        filterQuery,
    );

    return (
        <div className={styles.container}>
            <StoriesList stories={stories} withoutHighlightedStory />

            {canLoadMore && (
                <Button
                    variation="primary"
                    onClick={loadMoreStories}
                    isLoading={isLoading}
                    className={styles.loadMore}
                >
                    {formatMessage(
                        isLoading ? translations.misc.stateLoading : translations.actions.loadMore,
                    )}
                </Button>
            )}
        </div>
    );
}

export default InfiniteStories;
