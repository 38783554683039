import { type PaginationProps } from '@prezly/theme-kit-nextjs';

import type { StoryWithImage } from 'types';

import InfiniteStories from '../InfiniteStories';
import Layout from '../Layout';

interface Props {
    featuredStories: StoryWithImage[];
    stories: StoryWithImage[];
    pagination: PaginationProps;
}

function Stories({ stories, featuredStories, pagination }: Props) {
    return (
        <Layout>
            <InfiniteStories
                featuredStories={featuredStories}
                initialStories={stories}
                pagination={pagination}
            />
        </Layout>
    );
}

export default Stories;
